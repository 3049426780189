import React from "react";
import FormOne from "../contact/FormOne";

const AboutOne = () => {
  return (
    <section className="section section-padding-equal bg-color-light">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-us">
              <div className="section-heading heading-left mb-0">
                <span className="subtitle">About Us</span>
                <h2 className="title mb--40">
                  We do design, code &amp; develop.
                </h2>
                <p>
                  At our software house, we specialize in delivering top-tier
                  services across graphic design, web development, online
                  marketing, business consulting, eCommerce solutions, and
                  content writing. Our team of experts is dedicated to helping
                  businesses achieve their goals through innovative solutions,
                  tailored strategies, and a commitment to excellence.{" "}
                </p>
                <p>
                  With a client-centric approach, we ensure every project meets
                  the highest standards of quality and satisfaction. Trust us to
                  transform your vision into reality and drive your business
                  success.{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box">
              <h3 className="title">Get a free We Vertex quote now</h3>
              <FormOne />
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
            alt="line"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
            alt="line"
          />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
