import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";

const Datas = [
  {
    id: 1,
    title: "Expertise Across Diverse Industries",
    para: "Our team has extensive experience across multiple sectors, ensuring tailored solutions that address the unique needs of your business.",
  },
  {
    id: 2,
    title: "Commitment to Quality",
    para: "We prioritize delivering high-quality work, ensuring that every project meets the highest standards of excellence and client satisfaction",
  },
  {
    id: 3,
    title: "Innovative and Customized Solutions",
    para: "We craft innovative strategies and solutions tailored to your specific business goals, helping you stay ahead of the competition",
  },
  {
    id: 4,
    title: "Client-Centric Approach",
    para: "We focus on understanding your needs and preferences, ensuring that our solutions align with your vision and deliver maximum value.",
  },
  {
    id: 5,
    title: "Timely Delivery and Support",
    para: "We respect your timelines and provide ongoing support, ensuring that your projects are completed on time and with continued assistance as needed.",
  },
];

const AboutThree = () => {
  return (
    <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
      <div className="container">
        <SectionTitle
          subtitle="Our Values"
          title="Why should you work with us?"
          description="We offer expert, client-focused services with a commitment to quality, innovation, and timely delivery, ensuring your success."
          textAlignment="heading-left heading-light-left mb--100"
          textColor=""
        />

        <div className="row">
          {Datas.map((data) => (
            <div className="col-lg-4" key={data.id}>
              <div className="about-quality">
                <h3 className="sl-number">{data.id}</h3>
                <h5 className="title">{data.title}</h5>
                <p>{data.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
            alt="Circle"
          />
        </li>
      </ul>
    </div>
  );
};

export default AboutThree;
