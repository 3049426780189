import React from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaBehance,
  FaPhone,
  FaFax,
  FaInstagram,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  return (
    <Offcanvas
      show={offcanvasShow}
      onHide={offcanvasHide}
      placement="end"
      className="header-offcanvasmenu"
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <form action="#" className="side-nav-search-form">
          <div className="form-group">
            <input
              type="text"
              className="search-field"
              name="search-field"
              placeholder="Search..."
            />
            <button className="side-nav-search-btn">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </form>
        <div className="row ">
          <div className="col-lg-5 col-xl-6">
            <ul className="main-navigation list-unstyled">
              <li>
                <Link to={process.env.PUBLIC_URL + "/"}>We Vertex</Link>
              </li>
              {/* <li>
                <Link to={process.env.PUBLIC_URL + "/creative-agency"}>
                  Creative Agency
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/personal-portfolio"}>
                  Personal Portfolio
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/home-startup"}>
                  Home Startup
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/corporate-agency"}>
                  Corporate Agency
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="col-lg-7 col-xl-6">
            <div className="contact-info-wrap">
              <div className="contact-inner">
                <address className="address">
                  <span className="title">Contact Information</span>
                  <p>
                    Plot No# 70-C DHA <br /> Phase 2 Commercial Market A
                  </p>
                </address>
                <address className="address">
                  <span className="title">We're Available 24/7. Call Now.</span>
                  <a href="tel:8884562790" className="tel">
                    <FaPhone />
                    +1(202) 773‑7992
                  </a>
                  {/* <a href="tel:12125553333" className="tel">
                    <FaFax /> (121) 255-53333
                  </a> */}
                </address>
              </div>
              <div className="contact-inner">
                <h5 className="title">Find us here</h5>
                <div className="contact-social-share">
                  <ul className="social-share list-unstyled">
                    <li>
                      <a
                        target="blank"
                        href="https://www.facebook.com/varevertex?mibextid=LQQJ4d"
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        href="https://x.com/We_Vertex?fbclid=IwY2xjawEbmldleHRuA2FlbQIxMAABHabOV0pJM5a53Kza-x4dsuqjpDtQeMyQN09BUb50w42MAVPu52I208ed4Q_aem_ep72AJ5uYJLnwym7EKrPvA"
                      >
                        <FaXTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        href="https://www.instagram.com/we_vertex/?fbclid=IwY2xjawEbmQhleHRuA2FlbQIxMAABHbrcRsDp4sLsWrYOch6kShswR7PkGqNjdUFvadSSBEOST_Wx-ECK9GTe-g_aem_bEJpCDln24cg5V0cFQCdTg"
                      >
                        <FaInstagram />
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://www.linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
